import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <div className="container">
        <h1>Not Found</h1>
        <p>
          You just hit a route that doesn&#39;t exist. Check out the links above to get where you
          need to go.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
